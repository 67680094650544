<script lang="ts" setup>
import { promiseTimeout } from '@vueuse/core'
import { useFlag } from '@unleash/proxy-client-vue'
import { Facet } from '~/types/api'
import theme from '@/tailwind.config.cjs'

const emit = defineEmits(['fetch'])

const { t } = useI18n()
const isMobile = useIsMobile()
const itemsStore = useItemsStore()
const modalState = useModalState()
const activeParent = ref<Facet | null>(null)
const selectedParent = ref<Facet | null>(null)
const selectedChildren = ref<Facet | null>(null)

const isPromotionsFilterEnabled = useFlag('storefront.promotions-filter')

const handleTogglePromotionsFilter = () => {
  if (itemsStore.filterByPromotion) return

  selectedParent.value = null
  selectedChildren.value = null

  itemsStore.clearOnSearch()
  itemsStore.facetBy = null
  itemsStore.filterByPromotion = true
  emit('fetch')
}

const isParentActive = (facet: Facet) => {
  return selectedParent.value?.id === facet.id
}

const handleSelectParent = async (facet: Facet | null) => {
  if (selectedParent.value === null && facet === null && !itemsStore.filterByPromotion) return

  itemsStore.filterByPromotion = false

  if (facet?.hasChildren) {
    activeParent.value = facet
    await promiseTimeout(100)
    modalState.open()
    return
  }

  if (facet && facet.id === selectedParent.value?.id) return

  selectedParent.value = facet
  selectedChildren.value = facet

  itemsStore.clearOnSearch()
  itemsStore.facetBy = facet
  itemsStore.term = facet?.value || undefined
  emit('fetch')
}

const handleSelectChildren = (facet: Facet) => {
  if (facet.id === selectedChildren.value?.id) return

  selectedParent.value = facet?.parent || facet
  selectedChildren.value = facet

  itemsStore.clearOnSearch()
  itemsStore.facetBy = facet
  itemsStore.term = facet?.value || undefined

  if (facet.parent) {
    itemsStore.term = `${facet.parent.value}_${facet?.value}`
  } else {
    itemsStore.term = facet?.value || undefined
  }

  modalState.close()
  emit('fetch')
}

watch(
  () => itemsStore.term,
  (term) => {
    if (!term) {
      selectedParent.value = null
      selectedChildren.value = null
      activeParent.value = null
    }
  },
)

onMounted(async () => {
  const term = itemsStore.term
  itemsStore.term = undefined

  if (isPromotionsFilterEnabled.value) {
    await itemsStore.loadFacetsV2()
  } else {
    await itemsStore.loadFacets()
  }

  if (!term) return

  const [parentValue, childrenValue] = term.split('_')

  if (!parentValue && !childrenValue) return

  const facet = itemsStore.facets.find((facet) => {
    if (!childrenValue) return facet.value === parentValue || facet.parent?.value === parentValue

    return childrenValue === facet.value
  })

  if (!facet) {
    emit('fetch')
    return
  }

  if (childrenValue) {
    handleSelectChildren(facet)
    return
  }

  handleSelectParent(facet.parent || facet)
})

const handleClickBreadcrumb = (name: string) => {
  if (name === selectedParent.value?.value) {
    return handleSelectChildren(selectedParent.value)
  }
  handleSelectParent(null)
}
</script>

<template>
  <div
    v-if="itemsStore.facets.length || itemsStore.hasPromotions"
    class="flex gap-2 overflow-x-auto py-1 md:flex-wrap md:justify-center md:overflow-x-visible"
  >
    <RLabel
      v-if="itemsStore.hasPromotions"
      class="shadow-sm"
      :active="itemsStore.filterByPromotion"
      :custom-active-color="theme.theme.extend.colors.red[300]"
      @click="handleTogglePromotionsFilter"
    >
      <span class="flex items-center gap-1">
        <RIcon size="18" name="ic:round-local-offer" />
        {{ t('globals.promotions.title') }}
      </span>
    </RLabel>

    <RLabel
      :active="!selectedParent && !itemsStore.filterByPromotion"
      class="shadow-sm"
      @click="handleSelectParent(null)"
    >
      {{ t('globals.all') }}
    </RLabel>
    <RProductFacetsPopover
      v-for="facet in itemsStore.sortedParents"
      :key="facet.value"
      :selected-children="selectedChildren"
      :show-icon="!!facet.hasChildren && !isMobile"
      :disabled="isMobile || !facet.hasChildren"
    >
      <template #trigger>
        <RLabel
          :active="isParentActive(facet)"
          :class="{ 'md:pr-6': facet.hasChildren }"
          @click="handleSelectParent(facet)"
        >
          <div
            class="flex items-center justify-center gap-1"
            :class="{ 'mr-1': facet.hasChildren && !isMobile }"
          >
            {{ facet.value }}
          </div>
        </RLabel>
      </template>
      <RProductFacetsList
        :list="itemsStore.childrenFacets(facet.id)"
        :selected-children="selectedChildren"
        :active-parent="activeParent"
        @select="handleSelectChildren"
      />
    </RProductFacetsPopover>
    <RProductFacetsDrawer
      v-if="isMobile"
      v-model:show="modalState.isOpen.value"
      :active-parent="activeParent"
    >
      <RProductFacetsList
        v-if="activeParent"
        :list="itemsStore.childrenFacets(activeParent.id)"
        :active-parent="activeParent"
        :selected-children="selectedChildren"
        @select="handleSelectChildren"
      />
    </RProductFacetsDrawer>
  </div>
  <RBreadcrumb
    v-if="itemsStore.facetsValueTree.length"
    :items="[t('globals.homePage'), ...itemsStore.facetsValueTree]"
    @click="handleClickBreadcrumb"
  />
</template>
